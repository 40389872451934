/* Colors */
$primary-color: #DBC078; /* Light Gold */
$secondary-color: #2A2A2A; /* Dark Gray */
$text-color: #fff;
$light-bg: #f9f9f9;

/* General Page Styling */
.contact-us {
  font-family: 'Open Sans', sans-serif;
  background-color: $light-bg;
  padding: 120px 20px;
  color: $secondary-color;
}

/* Intro Section */
.contact-intro {
  text-align: center;
  margin-bottom: 50px;

  .intro-heading {
    color: $primary-color;
    font-weight: bold;
  }

  .intro-subtext {
    font-size: 1.2em;
    color: $secondary-color;
    max-width: 600px;
    margin: 0 auto;
  }
}

/* Contact Info Section */
.contact-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;

  .info-box {
    text-align: center;

    .info-title {
      color: $primary-color;
      font-size: 1.5em;
      margin-bottom: 10px;
    }

    .info-detail {
      font-size: 1.2em;
      color: $secondary-color;
    }
  }
}

/* Form Section */
.contact-form {
  max-width: 600px;
  margin: 0 auto;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .contact-input {
    color: $secondary-color;

    &::placeholder {
      color: $primary-color;
    }
  }

  .submit-btn {
    background-color: $primary-color;
    color: $secondary-color;
    padding: 10px;
    font-weight: bold;

    &:hover {
      background-color: lighten($primary-color, 10%);
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    gap: 20px;
  }

  .info-box {
    margin-bottom: 20px;
  }

  .contact-form {
    width: 100%;
  }
}
