$primary-color: #DBC078;
$secondary-color: #2A2A2A;
$background-color: #f9f9f9;
$text-color-dark: #333;
$text-color-light: #fff;

.checkout-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $background-color;
}

.checkout-container {
  background-color: $secondary-color;
  padding: 2rem;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.checkout-title {
  color: $primary-color;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.checkout-total {
  color: $primary-color;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.paysera-logo-container {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.paysera-logo {
  width: 150px; /* Adjust the size as needed */
}

.checkout-button {
  background-color: $primary-color;
  color: $secondary-color;
  padding: 0.75rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
