/* Colors */
$primary-color: #DBC078; /* Light Gold */
$secondary-color: #2A2A2A; /* Dark Gray */
$danger-color: #ff5f56; /* Red for delete button */
$text-color: #fff;

/* Cart Container */
.cart-container {
  padding: 2rem;
  background-color: $secondary-color;
  color: $text-color;
  border-radius: 8px;
}

/* Heading */
.cart-heading {
  color: $primary-color;
  font-weight: bold;
}

/* List Styles */
.cart-list {
  margin-bottom: 1.5rem;
}

/* Cart Item */
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Product Image Styling */
.cart-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
}

/* Item Name */
.item-name {
  color: $text-color;
  font-weight: bold;
}

/* Item Details */
.item-details {
  color: $text-color; /* Changed to white for better visibility */
  font-size: 1rem;
  font-weight: 500;
}

/* Actions (Quantity and Delete buttons) */
.cart-item-actions {
  display: flex;
  gap: 0.5rem;

  .quantity-btn {
    color: $primary-color;
  }

  .delete-btn {
    color: $danger-color;
  }
}

/* Item Total Price */
.item-total {
  color: $primary-color;
  font-weight: bold;
}

/* Divider */
.divider {
  background-color: $primary-color;
}

/* Cart Total */
.cart-total {
  margin-top: 1.5rem;
  font-weight: bold;
  color: $text-color;

  .total-amount {
    color: $primary-color;
  }
}

/* Shipping Options */
.shipping-options {
  margin-top: 1.5rem;
  background-color: $secondary-color;
  padding: 1rem;
  border-radius: 8px;
}

.shipping-heading {
  color: $primary-color;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.shipping-option {
  color: $text-color;
  margin-bottom: 0.5rem;

  /* Style the radio buttons */
  .MuiRadio-root {
    color: $primary-color;

    &.Mui-checked {
      color: $primary-color;
    }
  }

  .MuiTypography-root {
    color: $text-color;
  }
}

/* Checkout Button */
.checkout-btn {
  background-color: $primary-color;
  color: $secondary-color;
  margin-top: 1rem;

  &:hover {
    background-color: lighten($primary-color, 10%);
  }
}
.cart-discount {
    margin-top: 1rem;
    font-weight: bold;
    color: #ff5f56; // Red to highlight the discount amount
  
    .discount-amount {
      font-weight: bold;
      color: lighten(#ff5f56, 10%);
    }
  }

  /* Existing CSS above... */

/* Responsive Design for Mobile Screens */
@media (max-width: 600px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    .cart-item-image {
      width: 100%;
      height: auto;
      margin-right: 0;
    }

    .cart-item-actions {
      justify-content: flex-start;
      gap: 0.25rem;
      margin-top: 0.5rem;
    }

    .item-total {
      margin-top: 0.5rem;
    }

    .item-details {
      font-size: 0.9rem; 
    }

    .item-name {  
      margin-bottom: 0.25rem;
    }
  }

  .quantity-btn,
  .delete-btn {
    font-size: 0.8rem; 
  }
}

.customization-details {
  background-color: #DBC078; /* Gold-like color for a clean, contrasting background */
  border-radius: 8px;       /* Smooth edges for better aesthetics */
  padding: 12px;            /* Space between text and the edges of the box */
  margin: 10px 0;           /* Space around the customization box */
  color: #333333;           /* Dark gray for text to ensure readability */
}

.customization-title {
  font-weight: bold;
  margin-bottom: 8px;
  color: #1F1F1F;           /* Slightly darker for the title text */
}

.customization-list {
  list-style-type: none;    /* Remove default bullet points */
  padding: 0;
  margin: 0;
}

.customization-item {
  margin-bottom: 4px;
  font-size: 14px;
  color: #333333;           /* Ensure clear contrast against the background */
}

