/* Colors */
$primary-color: #DBC078; /* Light Gold */
$secondary-color: #2A2A2A; /* Dark Gray */
$lighter-gray: #f9f9f9; /* Lighter gray for form background */
$light-bg: #f9f9f9; /* Light background */

/* Full-page background styling */
.payment-success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $light-bg; /* Light background for consistency */
  padding: 2rem;
}

/* Container styling */
.payment-success-container {
  background-color: $lighter-gray;
  border-radius: 12px;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Title styling */
.payment-success-title {
  color: $primary-color;
  font-weight: bold;
  margin-bottom: 1rem;
}

.payment-failed {
  color: red;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Message styling */
.payment-message {
  color: $secondary-color;
  margin-bottom: 2rem;
}

/* Button styling */
.payment-button {
  background-color: $primary-color;
  color: $secondary-color;
  font-weight: bold;

  &:hover {
    background-color: lighten($primary-color, 10%);
  }
}

/* Loading spinner styling */
.loading-spinner {
  color: $primary-color;
}
