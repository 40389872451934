@import '../variables.scss';

.chartWrapper {
  margin-bottom: 30px;
  background-color: $card-background;
  padding: 15px;
  border-radius: 8px;
  box-shadow: $box-shadow;
  transition: all $transition-duration ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  }

  h6 {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.1rem;
    color: $text-color;
  }

  .chart {
    height: $chart-height;
  }
}
