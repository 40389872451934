// ReferrerTable.module.scss
.tableContainer {
    margin: 20px 0;
  }
  
  .tableWrapper {
    width: 100%;
    overflow-x: auto;
  }
  
  .tableWrapper table {
    min-width: 700px; /* Adjust as needed */
  }
  
  .urlCell {
    max-width: 300px; /* Limit the width */
    word-wrap: break-word; /* Break long URLs into multiple lines */
    overflow-wrap: break-word;
    white-space: normal; /* Allow text to wrap to the next line */
  }
  
  .filterBox {
    margin-bottom: 20px;
  }
  
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  