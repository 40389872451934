/* Colors */
$primary-color: #DBC078;  /* Light Gold */
$secondary-color: #2A2A2A;  /* Dark Gray */
$lighter-gray: #f9f9f9;  /* Lighter gray for backgrounds */
$text-color: #fff;
$light-bg: #f9f9f9; /* Light background for page */
$discount-color: #FF6347; /* Tomato color for discounted price */
$gray-color: lighten($secondary-color, 30%); /* Lightened gray for original price */

/* Main Product Detail Page Styling */
.product-detail {
  font-family: 'Open Sans', sans-serif;
  margin-top: 80px;
  padding: 30px 20px;
  background-color: $light-bg; /* Updated to use light background */
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  color: $secondary-color; /* Use dark text for contrast on light background */

  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5em;
    color: $primary-color;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 2em;
    }

    @media (max-width: 480px) {
      font-size: 1.8em;
    }
  }

  .product-detail-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $lighter-gray; /* Light gray background */
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      padding: 20px;
    }

    @media (max-width: 480px) {
      padding: 15px;
    }
  }

/* Product Images Carousel */
// .product-images {
//   width: 100%;
//   margin-bottom: 30px;

//   .carousel-slide {
//     position: relative;
//     width: 100%;
//     height: 500px; // Adjust as needed
//     overflow: hidden;

//     @media (max-width: 768px) {
//       height: 400px;
//     }

//     @media (max-width: 480px) {
//       height: 300px;
//     }

//     img {
//       width: 100%;
//       height: 100%;
//   object-fit: contain;
//       border-radius: 12px;
//       transition: transform 0.3s ease, box-shadow 0.3s ease;

//       &:hover {
//         transform: scale(1.05);
//         box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
//       }
//     }
//   }
// }

  
  

  /* Product Info Section */
  .product-info {
    width: 100%;
    max-width: 700px;
    text-align: left;
    background-color: $lighter-gray;
    padding: 25px;
    border-radius: 10px;
    color: $secondary-color;

    @media (max-width: 768px) {
      padding: 20px;
    }

    @media (max-width: 480px) {
      padding: 15px;
    }

    /* Product Description Styling */
    .product-description-container {
      background-color: rgba(219, 192, 120, 0.1);
      padding: 20px;
      margin-bottom: 30px;
      border-radius: 10px;
      border-left: 5px solid $primary-color;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

      @media (max-width: 768px) {
        padding: 15px;
      }

      @media (max-width: 480px) {
        padding: 10px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 1.8em;
        color: $primary-color;
        margin-bottom: 15px;

        @media (max-width: 768px) {
          font-size: 1.5em;
        }

        @media (max-width: 480px) {
          font-size: 1.3em;
        }
      }

      .product-description, .product-composition {
        font-size: 1.3em;
        line-height: 1.8;
        color: $secondary-color;

        @media (max-width: 768px) {
          font-size: 1.1em;
        }

        @media (max-width: 480px) {
          font-size: 1em;
        }
      }

    }

    /* Updated Nutrition Facts Styling with Golden Background */
    .nutrition-facts {
      margin-bottom: 30px;
      background-color: rgba(219, 192, 120, 0.1); /* Similar golden background as product description */
      padding: 20px;
      border-radius: 10px;
      border-left: 5px solid $primary-color;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

      @media (max-width: 768px) {
        padding: 15px;
      }

      @media (max-width: 480px) {
        padding: 10px;
      }

      h3 {
        font-size: 1.8em;
        margin-bottom: 15px;
        color: $primary-color;

        @media (max-width: 768px) {
          font-size: 1.5em;
        }

        @media (max-width: 480px) {
          font-size: 1.3em;
        }
      }

      .nutrition-table {
        width: 100%;
        border-collapse: collapse;
        background-color: #DBC078;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        border-left: 5px solid $primary-color;

        th, td {
          padding: 15px;
          text-align: left;
          border-bottom: 1px solid #7f8c8d;

          @media (max-width: 768px) {
            padding: 10px;
          }

          @media (max-width: 480px) {
            padding: 8px;
            font-size: 0.9em;
          }
        }

        th {
          font-weight: 700;
          color: $primary-color;
          background-color: rgba(42, 42, 42, 0.85);
        }

        td {
          color: $text-color;
          font-size: 1.1em;
        }

        tr:last-child td {
          border-bottom: none;
        }
      }
    }

    /* Add-to-Cart Button */
    .add-to-cart {
      width: 100%;
      padding: 15px;
      font-size: 1.2em;
      font-weight: 600;
      background-color: $primary-color;
      border: none;
      border-radius: 10px;
      color: $secondary-color;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
        transform: translateY(-2px);
      }

      &:active {
        transform: translateY(0);
      }

      @media (max-width: 768px) {
        padding: 12px;
        font-size: 1.1em;
      }

      @media (max-width: 480px) {
        padding: 10px;
        font-size: 1em;
      }
    }
  }

  @media (max-width: 768px) {
    .product-detail-content {
      padding: 20px;
    }

    // .product-images {
    //   margin-bottom: 20px;
    // }

    .product-info {
      .product-description-container {
        padding: 15px;
      }

      .nutrition-facts {
        padding: 15px;
      }

      .add-to-cart {
        padding: 12px;
        font-size: 1.1em;
      }
    }
  }

  @media (max-width: 480px) {
    .product-detail-content {
      padding: 15px;
    }

    .product-info {
      .product-description-container {
        padding: 10px;
        margin-bottom: 20px;
      }

      .add-to-cart {
        padding: 10px;
        font-size: 1em;
      }
    }
  }
}
/* Add to Cart Section */
.add-to-cart-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content */
    background-color: rgba(219, 192, 120, 0.1); /* Same background as product-description-container */
    padding: 25px;
    border-radius: 12px;
    margin-top: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 92%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .price-quantity-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
    .product-price {
        display: flex;
        align-items: baseline;
        gap: 10px;
      
        .original-price {
          font-size: 1.1em;
          color: $gray-color;
          text-decoration: line-through;
          opacity: 0.7;
        }
      
        .discounted-price {
          font-size: 1.6em;
          font-weight: bold;
          color: $discount-color;
        }
      
        .standard-price {
          font-size: 1.5em;
          font-weight: 700;
          color: $secondary-color;
        }
      }
  
    .quantity-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      
      .ant-input-number {
        margin-left: 15px;
        width: 100px;
        border-radius: 8px;
        font-size: 1.2em;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }
    }
  }
  
  .add-to-cart {
    width: 100%;
    padding: 15px;
    font-size: 1.3em;
    font-weight: bold;
    background-color: #DBC078;
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  
    &:hover {
      background-color: darken(#DBC078, 10%);
      transform: translateY(-3px);
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    }
  
    &:active {
      transform: translateY(0);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }
  