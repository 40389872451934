/* Colors */
$primary-color: #DBC078;  /* Light Gold */
$secondary-color: #2A2A2A;  /* Dark Gray */
$lighter-gray: #f9f9f9;    /* Lighter gray for backgrounds */
$text-color: #fff;
$light-bg: #f9f9f9;        /* Light background for page */
$discount-color: #FF6347;  /* Tomato color for discounted price */
$gray-color: lighten($secondary-color, 30%);

/* Main SpiceBoxDetail Page Styling */
.spice-box-detail {
  font-family: 'Open Sans', sans-serif;
  margin-top: 80px;
  padding: 30px 20px;
  background-color: $light-bg;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  color: $secondary-color;

  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5em;
    color: $primary-color;
    font-weight: 700;
  }

  .spice-box-detail-content {
    max-width: 700px; /* Added max-width to constrain content */
    width: 100%;
    margin: 0 auto;   /* Center the content */
    display: flex;
    flex-direction: column;
    align-items: center;

    .carousel-slide {
      width: 100%;
      max-width: 700px; /* Match the parent's max-width */
      margin: 0 auto;   /* Center the carousel */

      img {
        width: 100%;
        height: auto;       /* Adjust height automatically */
        max-height: 400px;  /* Set a max height */
        object-fit: cover;
        border-radius: 10px;
        transition: transform 0.3s ease; /* Ensure it does not conflict */
      }

      img:hover {
        transform: scale(1.05); /* Prevent excessive scaling */
      }
    }

    .spice-box-info {
      width: 100%;
      max-width: 700px;
      text-align: left;
      margin-top: 20px;

      .description-section,
      .size-section,
      .spices-section {
        margin-bottom: 20px;

        h3 {
          font-size: 1.5em;
          color: $secondary-color;
          margin-bottom: 10px;
        }

        p,
        li {
          font-size: 1.1em;
          color: #333;
        }

        ul {
          list-style: disc;
          padding-left: 20px;
        }
      }
    }

    .add-to-cart-section {
      width: 100%;
      max-width: 700px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        font-size: 1.8em;
        font-weight: bold;
        color: $primary-color;
      }

      .ant-input-number {
        width: 100px;
        border-radius: 8px;
      }

      .ant-btn-primary {
        background-color: $primary-color;
        border: none;
        font-weight: bold;
        padding: 10px 20px;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }
    }
  }

  .spices-section {
    ul {
      list-style: none;
      padding: 0;

      .spice-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .spice-info {
          flex: 1;
          font-size: 1.1em;
          margin-right: 10px;
        }

        .ant-input-number {
          width: 80px;
        }
      }
    }
  }
}

.spices-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.spice-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  strong {
    margin-bottom: 5px;
    font-size: 1.1em;
    color: #333;
  }

  .ant-input-number {
    width: 80px;
  }
}

.spice-tooltip {
  max-width: 200px;

  img {
    display: block;
    margin: 10px 0;
    border-radius: 5px;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  p {
    font-size: 0.9em;
    color: #555;
  }
}

.product-description-container {
  background-color: rgba(219, 192, 120, 0.1);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  border-left: 5px solid $primary-color;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.8em;
    color: $primary-color;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 1.5em;
    }

    @media (max-width: 480px) {
      font-size: 1.3em;
    }
  }

  .product-description, .product-composition {
    font-size: 1.3em;
    line-height: 1.8;
    color: $secondary-color;

    @media (max-width: 768px) {
      font-size: 1.1em;
    }

    @media (max-width: 480px) {
      font-size: 1em;
    }
  }
}

.spice-carousel {
  width: 100%;
  max-width: 700px; /* Restrict the carousel's width */
  margin: 0 auto; /* Center the carousel */
  position: relative; /* Ensure arrows are positioned correctly */

  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Set a fixed height for consistency */
    overflow: hidden;

    img.carousel-image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; /* Ensure proper scaling within the slide */
      border-radius: 10px;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .ant-carousel .slick-track {
    display: flex; /* Ensure proper alignment of slides */
    align-items: center;
  }

  /* Navigation Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10; /* Ensure arrows are above content */
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &::before {
      content: ''; /* Remove default Ant Design icons */
      display: block;
      width: 10px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
    }
  }

  .slick-prev {
    left: -50px; /* Position to the left of the carousel */
  }

  .slick-next {
    right: -50px; /* Position to the right of the carousel */
  }

  .slick-prev::before {
    transform: rotate(-135deg); /* Left-facing arrow */
  }

  .slick-next::before {
    transform: rotate(45deg); /* Right-facing arrow */
  }

}
  

