@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap');

* {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
  }
  
  .main-content {
    flex: 1; /* This makes the main content area expand to fill the available space */
    padding-top: 20px; /* Adjust based on your navbar's height */

  }