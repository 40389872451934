$footer-bg-color: #DBC078; /* Matching the light gold color */
$footer-text-color: #2A2A2A; /* Dark text color */
$footer-height: 60px; /* Footer height */
$divider-color: #C5A152; /* Gold color for divider */

/* Main Footer Styling */
.footer {
  background-color: $footer-bg-color;
  color: $footer-text-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $footer-height;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  position: relative;
  width: 100%;
  z-index: 1000; /* Ensure it appears on top of other content */
  border-top: 2px solid lighten($footer-bg-color, 20%);

  .footer-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    position: relative;

    /* Div styling to keep links 40% from sides */
    .left, .right {
      width: 40%;
      display: flex;
      justify-content: center;
    }

    /* Divider styling */
    .divider {
      width: 1px;
      height: 24px; /* Height of the divider */
      background-color: $divider-color; /* Gold color for divider */
      margin: 0 1rem; /* Space between the links and the divider */
    }

    /* Links style */
    a {
      color: $footer-text-color;
      text-decoration: none;
      font-weight: bold;
      transition: color 0.3s ease;

      &:hover {
        color: lighten($footer-text-color, 20%);
      }
    }
  }
}
