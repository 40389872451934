/* Navbar.scss */
$navbar-bg-color: #1a1a1a;
$navbar-border-color: #ffc533;
$logo-height-large: 105px; /* Increased logo size for desktop */
$icon-color: #fff;
$navbar-padding: 0 40px;
$navbar-height: 80px; /* Navbar height for desktop */

/* Main Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $navbar-padding;
  background-color: $navbar-bg-color;
  height: $navbar-height;
  box-shadow: 0 4px 10px -2px rgba(255, 197, 51, 0.6);
  position: fixed;
  width: 100%;
  z-index: 1000;

  /* Left Menu Links */
  .left-menu {
    display: flex;
    gap: 20px;
    a {
      color: $icon-color;
      font-size: 18px;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: $navbar-border-color;
      }
    }
  }

  /* Centered Logo */
  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -55px; /* Logo extending outside the navbar (for desktop) */
    z-index: 2000; /* Ensure logo is on top of other elements */

    img {
      height: $logo-height-large; /* Larger logo size for desktop */
      object-fit: contain;
    }
  }

  /* Desktop Menu */
  .desktop-menu {
    display: flex;
    align-items: center;

    .menu {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;

      .ant-menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        font-size: 18px;
        color: $icon-color;

        &:hover {
          color: $navbar-border-color;
        }

        .cart-icon {
          margin-left: auto;
          color: $icon-color;

          a {
            color: inherit;
          }

          .anticon {
            font-size: 28px;
          }
        }
      }
    }
  }
  
  .cart-badge {
    font-size: 16px;
    color: $icon-color;

    &.animated {
      animation: bounce 0.5s ease-out;
    }
  }

  @keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
  .navbar + .content-below-navbar {
    margin-top: 200px; /* Push content 30px lower */
  }
}

.language-selector {
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
  color: $icon-color;
  cursor: pointer;

  &:hover {
    color: $navbar-border-color;
  }

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
  }
}

/* No mobile-specific styles needed since MobileNavbar.tsx is used instead */
