
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background: #f7f7f7;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto;
  // padding-top: 120px; /* This value should match the height of the navbar */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background: #333;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  font-size: 1.2rem;
}

/* Mobile Menu */
.mobile-menu {
  display: none;
  flex-direction: column;
  background: #444;
  position: absolute;
  top: 50px;
  right: 15px;
  padding: 10px;
  border-radius: 5px;
}

.mobile-menu a {
  padding: 10px 0;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background: #fff;
  margin: 4px 0;
}

/* Responsive Styles */
/* Adjust container padding at smaller breakpoints */
@media (max-width: 768px) {
  .container {
    padding: 0 5px; /* Ensure padding is reduced */
  }

  .navbar {
    padding: 0 10px; /* Prevents overflow */
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 5px;
  }

  .navbar {
    padding: 0 8px; /* Slightly smaller padding */
  }
}



$particles: 50;
$width: 500;
$height: 500;

// Initialize the box-shadow values with an initial shadow
$box-shadow: none;
$box-shadow2: 0 0 #fff;

@for $i from 1 through $particles {
  $hue: random(360);
  $x: random($width) - $width / 2;
  $y: random($height) - $height / 1.2;

  @if $i == 1 {
    // Set the first shadow without a preceding comma
    $box-shadow: #{$x}px #{$y}px hsl($hue, 100%, 50%);
  } @else {
    // Add subsequent shadows with a preceding comma
    $box-shadow: #{$box-shadow}, #{$x}px #{$y}px hsl($hue, 100%, 50%);
  }

  // Update the second box-shadow
  $box-shadow2: #{$box-shadow2}, 0 0 #fff;
}

@mixin keyframes($animationName) {
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($name, $duration, $timing, $fill) {
  animation: $name $duration $timing $fill;
}

@mixin animation-delay($settings...) {
  animation-delay: $settings;
}

@mixin animation-duration($settings...) {
  animation-duration: $settings;
}

@mixin transform($settings) {
  transform: $settings;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.drizzle-effect {
  position: fixed;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: #{$box-shadow};
  
  @include animation(bang, 1s, ease-out, forwards);
  @include animation(gravity, 1s, ease-in, forwards);
  @include animation(position, 5s, linear, forwards);
}

.drizzle-effect.after {
  @include animation-delay(1.25s, 1.25s, 1.25s);
  @include animation-duration(1.25s, 1.25s, 6.25s);
}

@include keyframes(bang) {
  to {
    box-shadow: #{$box-shadow};
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
}
