$spice-card-bg: #f9f9f9;
$spice-card-border: #ddd;
$spice-card-shadow: rgba(0, 0, 0, 0.1);
$hover-shadow: rgba(0, 0, 0, 0.2);
$primary-color: #1abc9c; // A teal-like color
$secondary-color: #2c3e50;
$accent-color: #ecf0f1;

.spice-card {
  border: 1px solid $spice-card-border;
  border-radius: 15px;
  background-color: $spice-card-bg;
  box-shadow: 0 6px 10px -1px $spice-card-shadow;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px -3px $hover-shadow;
  }

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-bottom: 1px solid $spice-card-border;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-content {
    padding: 20px;

    .ant-card-meta-title {
      font-weight: bold;
      font-size: 1.4em;
      color: $secondary-color;
    }

    .spice-box-size {
      font-size: 1.1em;
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .spice-box-price {
      font-size: 1.2em;
      color: $secondary-color;
      margin-bottom: 10px;
    }

    .spice-box-description {
      font-size: 0.9em;
      color: $secondary-color;
      opacity: 0.8;
      margin-bottom: 15px;
    }

    .add-to-cart {
      width: 100%;
      background-color: $primary-color;
      border: none;
      color: #fff;
      font-weight: bold;
      border-radius: 8px;
      padding: 10px;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
        transform: translateY(-2px);
      }
    }
  }

}


