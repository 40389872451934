/* Colors */
$primary-color: #DBC078; /* Light Gold */
$secondary-color: #2A2A2A; /* Dark Gray */
$lighter-gray: #f9f9f9; /* Lighter gray for form background */
$text-color: #fff;
$light-bg: #f9f9f9; /* Light background */

/* Full-page background styling */
.details-form-page {
  font-family: 'Open Sans', sans-serif;
  background-color: $light-bg; /* Light background to match ContactUs page */
  padding: 120px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container styling */
.details-form-container {
  background-color: $lighter-gray; /* Light gray background for the form */
  border-radius: 12px;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Shadow to make it pop */
  position: relative;
  z-index: 1; /* Bring form to the top */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Add transform effect on hover to make it pop even more */
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
  }
}

/* Form heading */
.form-heading {
  color: $primary-color;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 2rem;
}

/* Styling the form */
.details-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Input fields placeholder and text styling */
.MuiOutlinedInput-root {
  color: $secondary-color; /* Dark text color when typing */

  & input::placeholder {
    color: $primary-color; /* Gold color for placeholders */
    opacity: 1; /* Ensure full visibility */
  }

  /* On focus, change the placeholder color to secondary */
  &.Mui-focused input::placeholder {
    color: $secondary-color; /* Dark color for placeholders */
  }

  /* Ensure the text color remains dark when typing */
  & input {
    color: $secondary-color; /* Dark text color */
  }
}

/* Label Styling (when input is focused or filled) */
.MuiInputLabel-root {
  color: $primary-color !important; /* Ensure label is gold initially */

  &.Mui-focused {
    color: $primary-color !important; /* Keep label gold when focused */
  }
}

/* Dropdown styling */
.MuiSelect-root {
  color: $secondary-color; /* Dark text color */
}

.MuiSelect-icon {
  color: $primary-color; /* Gold color for the dropdown icon */
}

/* Menu items styling */
.MuiMenuItem-root {
  background-color: $lighter-gray;
  color: $secondary-color;

  &:hover {
    background-color: lighten($lighter-gray, 10%);
  }
}

/* Button styling */
.submit-btn {
  background-color: $primary-color;
  color: $secondary-color;
  font-weight: bold;

  &:hover {
    background-color: lighten($primary-color, 10%);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .details-form-container {
    padding: 1.5rem;
  }

  .form-heading {
    font-size: 1.8rem;
  }
}
