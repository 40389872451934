.product-images {
  width: 30%; /* Restrict container to 30% of the screen width */
  max-width: 400px; /* Ensure container width does not exceed 400px */
  max-height: 400px; /* Ensure container height does not exceed 400px */
  margin: 0 auto; /* Center align */
  margin-bottom: 30px;
  overflow: hidden; /* Prevent overflow */

  @media (max-width: 768px) {
    width: 80%; /* Adjust width for tablets */
    max-width: none; /* Allow flexibility */
    max-height: 300px; /* Adjust for smaller screens */
  }

  @media (max-width: 480px) {
    width: 90%; /* Adjust width for smaller screens */
    max-width: none; /* Allow flexibility */
    max-height: 250px; /* Adjust for smaller screens */
  }

  .carousel-slide {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 12px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .swiper-button-next,
.swiper-button-prev {
  color: #333; /* Change button color */
  font-size: 24px; /* Increase button size */
  transition: color 0.3s ease;

  &:hover {
    color: #000; /* Change color on hover */
  }
}
}
