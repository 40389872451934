.banner {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden; /* Ensures the sides are clipped */
  
    .banner-background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
  
      .layer {
        flex: 1;
        height: 100%;
      }
  
      .light {
        background-color: #ffffff;
        opacity: 1;
      }
  
      .dark {
        background-color: #f7f7f7;
        opacity: 0.9;
      }
  
      .accent {
        background-color: #d0ad50;
        opacity: 0.6;
        mix-blend-mode: multiply;
      }
    }
  
    .banner-image {
      height: 100%; /* Keep image full height of banner */
      width: auto; /* Maintain aspect ratio */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center image */
    }
  
    .banner-content {
      position: relative;
      color: #000;
      text-align: center;
      z-index: 1;
  
      .banner-title {
        font-size: 2em;
        font-weight: bold;
        margin: 0;
      }
  
      .banner-subtitle {
        font-size: 1em;
        margin-top: 10px;
      }
    }
  
    @media (max-width: 768px) {
      height: 200px;
  
      .banner-content .banner-title {
        font-size: 1.5em;
      }
  
      .banner-content .banner-subtitle {
        font-size: 0.9em;
      }
    }
  }
  