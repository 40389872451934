@import '../variables.scss';

.tableWrapper {
  background-color: $card-background;
  padding: 15px;
  border-radius: 8px;
  box-shadow: $box-shadow;
  transition: all $transition-duration ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        background-color: $primary-color;
        color: #fff;
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: #f2f2f2;
        }

        &:hover {
          background-color: $secondary-color;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid $border-color;
    }
  }
}
