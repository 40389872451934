/* MobileNavbar.scss */
$navbar-bg-color: #1a1a1a;
$burger-color: #DBC078; /* Light Gold for burger button */
$icon-color: #fff;
$mobile-height: 80px; /* Height for the mobile navbar */
$logo-height-mobile: 60px; /* Adjust the height of the logo */
$burger-icon-size: 35px; /* Burger icon size */

/* Main Mobile Navbar Styling */
.mobile-navbar {
  display: flex;
  background-color: $navbar-bg-color;
  padding: 0 15px; /* Adjusted padding */
  height: $mobile-height; /* Navbar height */
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  box-sizing: border-box;

  .mobile-navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative; /* Ensure the logo positioning is relative to this container */

    .mobile-menu-button {
      font-size: $burger-icon-size;
      color: $burger-color;
      background: none;
      border: none;
      padding: 0;
      margin-top: -12px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      height: 100%; /* Ensures it's vertically centered */
    }

    .mobile-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1; /* Take up available space */
      position: relative; /* Enable absolute positioning of the logo */
      z-index: 2; /* Ensure the logo stays on top */
      margin-top: 15px; /* Shift it down slightly so it starts from the bottom of the navbar */

      .mobile-logo-img {
        height: $logo-height-mobile;
        position: relative;
        max-height: none; /* Ensure it doesn't get restricted */
        margin-top: -20px; /* Adjust this value to control how much of the logo overlaps below */
      }
    }

    .cart-icon-link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-right: 10px;
      flex-shrink: 0;
      width: 24px;
      height: 24px;

      .cart-badge {
        color: $icon-color;
        font-size: 14px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
    .mobile-navbar + .content-below-navbar {
        margin-top: 30px; /* Push content 30px lower */
      }
  }
}
