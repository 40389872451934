$primary-color: #DBC078; /* Light Gold */
$secondary-color: #2A2A2A; /* Dark Gray */
$text-color: #fff;

/* Container Styling */
.rules-container {
  padding: 2rem;
  padding-top: 75px;
  background-color: $secondary-color;
  color: $text-color;
  border-radius: 8px;
  max-width: 800px;
  margin: 2rem auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Heading Styling */
.rules-heading {
  color: $primary-color;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Section Title Styling */
.section-title {
  color: $primary-color;
  margin-top: 1rem;
  font-weight: bold;
}

/* Section Content Styling */
.section-content {
  color: $text-color;
  line-height: 1.6;
  margin-bottom: 1rem;
}
