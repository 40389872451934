body{
    overflow: auto;
}

/* Colors */
$primary-color: #DBC078;
$secondary-color: #2A2A2A;
$light-bg: #f9f9f9;
$text-color: #fff;

/* General Page Styling */
.about-us {
  font-family: 'Open Sans', sans-serif;
  color: $secondary-color;
  background-color: $light-bg;
  padding: 40px 20px;
}

/* Introduction Section */
.about-intro {
  text-align: center;
  padding: 60px 20px;
  background: $primary-color;
  color: $secondary-color;
  border-radius: 12px;
  margin-bottom: 50px;

  h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.5em;
    max-width: 800px;
    margin: 0 auto;
  }
}

/* Cards Section */
.about-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  padding: 20px;
}

.about-card {
  background-color: $secondary-color;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-bottom: 5px solid $primary-color;
  }

  .card-content {
    padding: 20px;
    text-align: center;
    color: $text-color;

    h2 {
      font-size: 2em;
      margin-bottom: 15px;
      color: $primary-color;
    }

    p {
      font-size: 1.2em;
      line-height: 1.6;
    }
  }
}

/* Timeline Section */
.timeline-section {
  margin-top: 80px;
  text-align: center;

  h2 {
    font-size: 2.5em;
    margin-bottom: 50px;
    color: $secondary-color;
  }

  .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      width: 6px;
      height: 100%;
      background-color: $primary-color;
      transform: translateX(-50%);
    }

    .timeline-item {
      padding: 20px;
      position: relative;
      width: 50%;
      left: 50%;
      transform: translateX(-100%);
      margin-bottom: 50px;

      &:nth-child(even) {
        left: 0;
        transform: translateX(0);
      }

      &::after {
        content: '';
        position: absolute;
        top: 25px;
        left: -13px;
        width: 25px;
        height: 25px;
        background-color: $primary-color;
        border-radius: 50%;
        border: 4px solid $light-bg;
      }

      .timeline-content {
        background-color: $secondary-color;
        color: $text-color;
        padding: 20px;
        border-radius: 12px;
        position: relative;

        h3 {
          font-size: 1.8em;
          color: $primary-color;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.2em;
        }
      }
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-intro {
    h1 {
      font-size: 2.2em;
    }

    p {
      font-size: 1.3em;
    }
  }

  .about-card {
    img {
      height: 200px;
    }

    .card-content h2 {
      font-size: 1.8em;
    }

    .card-content p {
      font-size: 1.1em;
    }
  }

  .timeline::before {
    left: 5%;
  }

  .timeline-item {
    width: 100%;
    transform: translateX(0);

    &::after {
      left: 20px;
    }
  }
}

.social-links {
    margin-top: 60px;
    text-align: center;
    
    h2 {
      font-size: 2.5em;
      margin-bottom: 20px;
      color: $secondary-color;
    }
  
    .social-icons {
      display: flex;
      justify-content: center;
      gap: 20px;
  
      a {
        font-size: 2.5em;
        color: $primary-color;
        transition: color 0.3s ease;
  
        &:hover {
          color: darken($primary-color, 10%);
        }
      }
    }
  }
  
  /* Responsive Timeline Design */
@media (max-width: 768px) {
  .timeline-section .timeline {
    &::before {
      left: 10px; /* Aligns the timeline line to the left */
    }

    .timeline-item {
      width: 100%; /* Full width for mobile */
      left: 0;
      transform: translateX(0);
      padding: 10px 0; /* Reduce padding for mobile */
      margin-bottom: 30px; /* Adjust margin for better spacing */

      &::after {
        left: 50%; /* Centers the circle on the line */
        transform: translateX(-50%);
      }
    }
    .timeline-item::after {
      width: 15px; /* Adjust size */
      height: 15px; /* Adjust size */
      top: 10px; /* Move the dot up to create more space */
      left: 50%; /* Center the dot */
      transform: translateX(-50%);
    }
  }

  .timeline-section .timeline-content {
    padding: 15px; /* Adjust padding */
    margin-top: 15px; /* Adjust this value as needed */

  }
}
