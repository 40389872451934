/* ProductCard.scss */
$product-card-bg: #fff;
$product-card-border: #ddd;
$product-card-shadow: rgba(0, 0, 0, 0.1);
$product-hover-shadow: rgba(0, 0, 0, 0.2);
$primary-color: #1890ff;
$secondary-color: #2A2A2A;
$accent-color: #DBC078;
$button-hover-color: darken($primary-color, 10%);
$discount-color: #FF6347; // Tomato color for discounted price

.product-card {
  position: relative;
  border: 1px solid $product-card-border;
  border-radius: 15px;
  background-color: $product-card-bg;
  box-shadow: 0 6px 10px -1px $product-card-shadow;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px -3px $product-hover-shadow;
  }

  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-bottom: 1px solid $product-card-border;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-content {
    padding: 20px;

    .ant-card-meta-title {
      font-weight: bold;
      font-size: 1.3em;
      color: $secondary-color;
    }

    .ant-card-meta-description {
      color: $accent-color;
      font-size: 1.1em;
      margin-bottom: 15px;
    }

    /* Add-to-Cart Button */
    .add-to-cart {
      width: 100%;
      background-color: $primary-color;
      border: none;
      color: #fff;
      font-weight: bold;
      border-radius: 8px;
      padding: 10px;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: $button-hover-color;
        transform: translateY(-2px);
      }
    }

    /* Price container with styles for discounted and original prices */
    .price-container {
      display: flex;
      align-items: baseline;
      gap: 8px;
    }

    .discounted-price {
      font-size: 1.4em;
      font-weight: bold;
      color: $discount-color;
    }

    .original-price {
      font-size: 1em;
      color: $secondary-color;
      text-decoration: line-through;
      opacity: 0.6;
    }

    .standard-price {
      font-size: 1.3em;
      color: $accent-color;
    }
  }
}
.product-card {
  position: relative;
  border: 1px solid $product-card-border;
  border-radius: 15px;
  background-color: $product-card-bg;
  box-shadow: 0 6px 10px -1px $product-card-shadow;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px -3px $product-hover-shadow;
  }

  &.sold-out {
    opacity: 0.5; // Gray out the card
    pointer-events: none; // Disable hover and clicks
  }

  .sold-out-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 15px;
    text-transform: uppercase;
  }

  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-bottom: 1px solid $product-card-border;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-content {
    padding: 20px;

    .ant-card-meta-title {
      font-weight: bold;
      font-size: 1.3em;
      color: $secondary-color;
    }

    .ant-card-meta-description {
      color: $accent-color;
      font-size: 1.1em;
      margin-bottom: 15px;
    }

    .add-to-cart {
      width: 100%;
      background-color: $primary-color;
      border: none;
      color: #fff;
      font-weight: bold;
      border-radius: 8px;
      padding: 10px;
      transition: background-color 0.3s ease, transform 0.3s ease;

      &:hover {
        background-color: $button-hover-color;
        transform: translateY(-2px);
      }

      &:disabled {
        background-color: gray;
        cursor: not-allowed;
      }
    }
  }
}

