// CombinedAnalytics.module.scss

$primary-color: #4a90e2;
$secondary-color: #82ca9d;
$background-color: #f8f9fa;
$card-background: #ffffff;
$border-color: #e0e0e0;
$text-color: #333;
$chart-height: 400px;
$transition-duration: 0.3s;
$box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

.container {
  margin-top: 30px;
  padding: 20px;
  background-color: $background-color;
  border-radius: 8px;
  box-shadow: $box-shadow;

  h4 {
    color: $primary-color;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .timeRangeSelector {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    h6 {
      margin-right: 10px;
      font-weight: 500;
      color: $text-color;
    }

    .selectBox {
      select {
        padding: 8px 12px;
        border: 1px solid $border-color;
        border-radius: 4px;
        transition: all $transition-duration ease;
        &:hover {
          border-color: $primary-color;
        }
      }
    }
  }

  .chartWrapper {
    margin-bottom: 30px;
    background-color: $card-background;
    padding: 15px;
    border-radius: 8px;
    box-shadow: $box-shadow;
    transition: all $transition-duration ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    }

    h6 {
      text-align: center;
      margin-bottom: 15px;
      font-size: 1.1rem;
      color: $text-color;
    }

    .chart {
      height: $chart-height;
    }
  }

  .tableContainer {
    margin-top: 20px;
    .tableWrapper {
      background-color: $card-background;
      padding: 15px;
      border-radius: 8px;
      box-shadow: $box-shadow;
      transition: all $transition-duration ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
      }

      table {
        width: 100%;
        border-collapse: collapse;

        thead {
          tr {
            background-color: $primary-color;
            color: #fff;
          }
        }

        tbody {
          tr {
            &:nth-of-type(even) {
              background-color: #f2f2f2;
            }

            &:hover {
              background-color: $secondary-color;
              color: #fff;
              cursor: pointer;
            }
          }
        }

        th,
        td {
          padding: 10px;
          text-align: left;
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }
}
