/* Colors */
$primary-color: #DBC078; /* Light Gold */
$secondary-color: #2A2A2A; /* Dark Gray */
$lighter-gray: #3A3A3A; /* Lighter gray for the form background */
$text-color: #fff;

/* Full-page background styling */
.details-form-page {
  min-height: 100vh; /* Extend background to full height */
  background-color: $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 110px 20px; /* Ensure some padding around the edges */
}

/* Container styling */
.details-form-container {
  background-color: $lighter-gray; /* Lighter gray background for the form */
  border-radius: 12px;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Shadow to make it pop */
  position: relative;
  z-index: 1; /* Bring form to the top */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  /* Add transform effect on hover to make it pop even more */
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
  }
}

/* Form heading */
.form-heading {
  color: $primary-color;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 2rem;
}

/* Styling the form */
.details-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Input fields placeholder and text styling */
.MuiOutlinedInput-root {
  color: $text-color; /* White text color when typing */

  & input::placeholder {
    color: $primary-color; /* Gold color for placeholders */
    opacity: 1; /* Ensure full visibility */
  }

  /* On focus, change the placeholder color to white */
  &.Mui-focused input::placeholder {
    color: $text-color; /* White color for placeholders */
  }

  /* Ensure the text color remains white when typing */
  & input {
    color: $text-color; /* White text color when typing */
  }
}

/* Label Styling (when input is focused or filled) */
.MuiInputLabel-root {
  color: $primary-color !important; /* Ensure label is gold initially */

  &.Mui-focused {
    color: $primary-color !important; /* Keep label gold when focused */
  }
}

/* Button styling */
.submit-btn {
  background-color: $primary-color;
  color: $secondary-color;
  font-weight: bold;

  &:hover {
    background-color: lighten($primary-color, 10%);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .details-form-container {
    padding: 1.5rem;
  }

  .form-heading {
    font-size: 1.8rem;
  }
}
